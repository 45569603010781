.section_container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: #f9fafb;
    width: 100%;
    align-items: center;
    padding: 7.8% 5.5% 7.8% 5.5%;
    @media (max-width: 460px) {
        padding: 7.8% 16px 7.8% 16px;
    }
}

.cards_container {
    width: calc(100vw - 5.5% - 5.5%);
    overflow: scroll;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    gap: 0px 16px;
    align-items: center;
    justify-content: center;
    @media (max-width: 850px) {
        justify-content: unset;
        gap: 0px 8px;
        width: calc(100vw - 8px - 8px);
    }
    @media (max-width: 460px) {
        width: calc(100vw - 8px - 8px);
        justify-content: unset;
    }
}

.card_container {
    width: 200px;
    height: 138px;
    @media (max-width: 460px) {
        width: 148px;
        height: 132px;
    }
}
